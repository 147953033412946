import {ReactElement} from 'react';
import AboutMe from './components/aboutme/AboutMe';
import ContactForm from './components/contact/ContactForm';
import Welcome from './components/welcome/Welcome';
import styles from './App.module.css';


function App(): ReactElement {
  return (
    <div className={styles.container}>
      <Welcome/>
      <AboutMe/>
      <ContactForm/>
    </div>
  );
}

export default App;
