import {
  InputText as PrimeInputText,
  InputTextProps as PrimeInputTextProps
} from 'primereact/inputtext';
import { nanoid } from 'nanoid';
import { Controller, useFormContext } from 'react-hook-form';

type InputTextProps = PrimeInputTextProps & {
  fieldName: string;
  label?: string;
};

const InputText = (props: InputTextProps) => {
  const id = nanoid();
  const { control } = useFormContext();
  return (
    <span className="p-float-label w-full mb-3">
      <Controller
        name={props.fieldName}
        control={control}
        render={({ field }) => (
          <PrimeInputText id={id} className={'w-full'} {...field} />
        )}
      />
      <label htmlFor={id}>{props.label}</label>
    </span>
  );
};

export { InputText };
