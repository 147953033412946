import { ReactElement } from 'react';
import styles from './Welcome.module.css';
import logo from './DR-logos_white.png';
import { useTranslation } from 'react-i18next';

const Welcome = (): ReactElement => {
  const { t } = useTranslation('welcome');
  return (
    <div className={styles.homeContainer}>
      <div>
        <img src={logo} alt={logo} className={styles.welcomeLogo} />
        <h1 className={styles.welcomeH1}>{t('phrase')}</h1>
      </div>
      <i className={'fas fa-chevron-down fa-3x ' + styles.welcomeDown} />
    </div>
  );
};

export default Welcome;
