import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import * as welcome from '../public/locales/en/welcome.json';
import * as aboutMe from '../public/locales/en/aboutme.json';
import * as contactForm from '../public/locales/en/contactForm.json';

const resources = {
  en: {
    welcome,
    aboutMe,
    contactForm
  }
};

i18n.use(initReactI18next).init({
  resources,
  ns: ['welcome, aboutMe, contactForm'],
  lng: 'en',
  fallbackLng: 'en',
  interpolation: {
    escapeValue: false
  }
});
