import { ReactElement } from 'react';
import styles from './AboutMe.module.css';
import me from './me.jpg';
import docker from './docker-icon.svg';
import gitlab from './gitlab-icon.svg';
import graphql from './graphql-icon.svg';
import kubernetes from './kubernetes-icon.svg';
import react from './reactjs-icon.svg';
import spring from './springio-icon.svg';
import { useTranslation } from 'react-i18next';

const AboutMe = (): ReactElement => {
  const { t } = useTranslation('aboutMe');
  return (
    <div className={styles.aboutContainer}>
      <img src={me} alt={'That is me!'} className={styles.aboutImage} />
      <h1 className={styles.content}>{t('phrase')}</h1>
      <div className={styles.imageContainer}>
        <img alt="docker" src={docker} className={styles.image1} />
        <img alt="gitlab" src={gitlab} className={styles.image2} />
        <img alt="graphql" src={graphql} className={styles.image3} />
        <img alt="kubernetes" src={kubernetes} className={styles.image4} />
        <img alt="react" src={react} className={styles.image5} />
        <img alt="spring" src={spring} className={styles.image6} />
      </div>
    </div>
  );
};

export default AboutMe;
