import {ReactElement} from 'react';
import styles from './HangingBoard.module.css';

const HangingBoard = (): ReactElement=> {
  return (
    <div  className={styles.container}>
      <div className={styles.first} />
      <div className={styles.second}>
        <a href="mailto:me@danielrieser.de">me@danielrieser.de</a>
      </div>
    </div>
  );
};

export default HangingBoard;