import {
  InputTextarea as PrimeInputTextArea,
  InputTextareaProps as PrimeInputTextareaProps
} from 'primereact/inputtextarea';
import { nanoid } from 'nanoid';
import { Controller, useFormContext } from 'react-hook-form';

type InputTextareaProps = PrimeInputTextareaProps & {
  fieldName: string;
  label?: string;
};

const InputTextArea = (props: InputTextareaProps) => {
  const { control } = useFormContext();
  const id = nanoid();
  return (
    <span className="p-float-label w-full mb-3">
      <Controller
        name={props.fieldName}
        control={control}
        render={({ field }) => (
          <PrimeInputTextArea className={'w-full'} {...field} />
        )}
      />
      <label htmlFor={id}>{props.label}</label>
    </span>
  );
};

export { InputTextArea };
