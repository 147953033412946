import { ReactElement } from 'react';
import styles from './ContactForm.module.css';
import { init, send } from 'emailjs-com';
import HangingBoard from './HangingBoard';
import { Button } from '../../ui/Button';
import { Card } from '../../ui/Card';
import { useForm, FormProvider } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { InputText } from '../../ui/input-text/InputText';
import { InputTextArea } from '../../ui/input-text-area/InputTextArea';

init('user_gsaCTYqPI16H68Fo8rmzq');

const ContactForm = (): ReactElement => {
  const { t } = useTranslation('contactForm');
  const methods = useForm({
    defaultValues: { mail: '', content: '' }
  });

  const onSubmit = async (data: { mail: string; content: string }) => {
    await send('service_yiatt5z', 'template_svzkn1g', {
      from_name: data.mail,
      message: data.content
    });
  };
  return (
    <div className={styles.contactContainer}>
      <Card className={styles.card}>
        <FormProvider {...methods}>
          <form
            className={
              styles.form + ' flex flex-column justify-content-between'
            }
            onSubmit={methods.handleSubmit(onSubmit)}
          >
            <InputText label={t('eMail.label')} fieldName={'mail'} />
            <InputTextArea label={t('content.label')} fieldName={'content'} />
            <Button
              type="submit"
              className={styles.marginTop + ' ' + styles.formButton}
            >
              {t('submitButton')}
            </Button>
          </form>
        </FormProvider>
      </Card>
      <HangingBoard />
    </div>
  );
};

export default ContactForm;
